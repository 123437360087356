import { Card, CardContent, CardMedia, Typography } from "@mui/material";
import React, { PropsWithChildren } from "react";
import { GameDescriptionProps, VIDEOGAMES_IMAGES_PATH } from "../../types";
import { GamePurchaseLinks } from "./GamePurchaseLinks";

const descriptionFormat = (description: string) => {
  const formattedDescription = description.split("\n").map((line, index) => (
    <React.Fragment key={index}>
      {line}
      <br />
    </React.Fragment>
  ));

  return formattedDescription;
};

export const GameDescription: React.FC<
  PropsWithChildren<GameDescriptionProps>
> = ({
  category,
  description,
  downloadLinksId,
  imageUrl,
  isMultiplayer,
  isSplitScreen,
  title,
}) => {
  return (
    <Card sx={{ maxWidth: 600, margin: "auto", marginBottom: 4 }}>
      <CardMedia
        component="img"
        height="300"
        image={`${VIDEOGAMES_IMAGES_PATH}${imageUrl}`}
        alt={title}
      />
      <CardContent
        sx={{
          gap: 2,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Typography gutterBottom variant="h5" component="div">
          {title}
        </Typography>
        <Typography variant="body2">
          {descriptionFormat(description)}
        </Typography>
        <Typography variant="body2">Category: {category}</Typography>
        <Typography variant="body2">
          Multiplayer: {isMultiplayer ? "Yes" : "No"}
        </Typography>
        <Typography variant="body2">
          Split-Screen: {isSplitScreen ? "Yes" : "No"}
        </Typography>
        <GamePurchaseLinks videogameLinkId={downloadLinksId} />
      </CardContent>
    </Card>
  );
};
