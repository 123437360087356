import { Box } from "@mui/material";
import { Header } from "./components/Header";
import { Outlet } from "react-router-dom";

function App() {
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        height: "100%",
        flexFlow: "column",
      }}
    >
      <Header />
      <Outlet />
    </Box>
  );
}

export default App;
