import { create } from 'zustand';
import { devtools } from 'zustand/middleware'
import { GameDescriptionProps, GameLinks } from '../types';

type State = {
  activeMenu: string;
  videogames: GameDescriptionProps[];
  videogameLinks: GameLinks[];
};

type Action = {
  updateActiveMenu: (activeMenu: State['activeMenu']) => void;
  addVideoGamesLinks: (videogameLinks: GameLinks[]) => void;
};


// Create your store, which includes both state and (optionally) actions
const useABStore = create<State & Action>()(
  devtools((set) => ({
    activeMenu: "",
    updateActiveMenu: (menu) => set(() => ({ activeMenu: menu })),
    videogames: [],
    videogameLinks: [],
    addVideoGamesLinks: (videogameLinks) => set((state) => ({
      videogameLinks: [...state.videogameLinks, ...videogameLinks]
    })),
  })));

export default useABStore;

