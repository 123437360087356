import { Grow, MenuItem, Typography, useTheme } from '@mui/material';
import React, { FC, PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';
import { Pages } from './Header';

type MenuItemLinksProps = {
    activeMenu: string;
    handleClose?: () => void;
    isMobileMenu?: boolean;
    linkStyles?: React.CSSProperties;
    pages: Pages[];
};


export const MenuItemLinks: FC<PropsWithChildren<MenuItemLinksProps>> = ({
    activeMenu,
    handleClose,
    isMobileMenu = false,
    linkStyles,
    pages,
}) => {

    const theme = useTheme();
    return (
        <>
            {pages.map(({ key, title }) => (
                <Grow
                    key={key}
                    in={true}
                    style={{ transformOrigin: '0 0 0' }}
                    {...{ timeout: 1000 }}
                >
                    <MenuItem
                        key={key}
                        selected={activeMenu === key}
                        onClick={handleClose}
                        sx={{
                            my: 2,
                            color: 'white',

                            display: 'block',
                            transition: 'all 0.3s ease-in-out',
                            '&:hover': {
                                boxShadow: 'none',
                                color: "#ddaf17",
                                transform: 'translateY(-3px)',
                            },
                            '&.Mui-selected': {
                                boxShadow: 'none',
                                color: "#ddaf17",
                                borderRadius: "0.5rem",

                            },

                            ...(isMobileMenu && {
                                '&:focus': {
                                    boxShadow: 'none',
                                    color: "#ddaf17",
                                    transform: 'translateY(-3px)',

                                },
                            }),
                        }}
                    >
                        <Link to={`/${key.toLowerCase()}`} style={{ ...linkStyles }}>
                            <Typography sx={{
                                fontWeight: theme.typography.fontWeightBold
                            }}>{title}</Typography>
                        </Link>
                    </MenuItem>
                </Grow>
            ))}
        </>
    );
};
