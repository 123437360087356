import { Box, Container, Paper } from "@mui/material";
import { ReactElement, lazy } from "react";
import { BlogDetail } from "../components/Blog";
const YouTube = lazy(() => import('../components/Content/YouTube'));
// import { VIDEOGAMES_IMAGES_PATH } from "../types";

const fortniteContent: ReactElement = (
    <YouTube videoId="LhZ3W-boSXg?si=-HPra-HvJ4G9fGzR" />
);

const squidContent: ReactElement = (
    <YouTube videoId="3jg4Z3twnjc?si=iiZzNAeSIqYLtOV0" />
);

{/* <BlogDetail title="Fortnite Chapter 2 ReMix the Finale" content={fortniteContent} date={""} imageUrl={`${VIDEOGAMES_IMAGES_PATH}fortniteremix.jpg`} /> */}
export const NewsPage = () => {
    return (
        <Container component={Paper} elevation={8} maxWidth="xl" sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
        }}>
            <h1>News</h1>
            <Box sx={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "center",
                gap: 2,
                mt: 3
            }}>
                <BlogDetail title="Call Of Duty and Squid Game 2" content={squidContent} date={""} />
                <BlogDetail title="Fortnite Chapter 2 ReMix the Finale" content={fortniteContent} date={""}  />

            </Box>
        </Container>
    );
}

