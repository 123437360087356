import { faPlaystation, faXbox, } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, IconButton, Typography } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import nintendoLogo from '../../assets/Videogames/nintendo-logo.svg';
import supabase from '../../utils/supabase';
import useABStore from '../../hooks/useAvBStore';

type GamePurchaseLinksProps = {
    nintendoLink?: string;
    playstationLink?: string;
    xboxLink?: string;
    videogameLinkId?: number;
};

    const purchaseLinksInitial: GamePurchaseLinksProps = {
        nintendoLink: undefined,
        playstationLink: undefined,
        xboxLink: undefined,
        videogameLinkId: 0
    }

export const GamePurchaseLinks: FC<GamePurchaseLinksProps> = ({ videogameLinkId }) => {
    const [purchaseLinks, setPurchaseLinks] = useState<GamePurchaseLinksProps>(purchaseLinksInitial);
    const { addVideoGamesLinks } = useABStore() ;

    useEffect(() => {
        fetchData();
    }, []);
    
    const fetchData = async () => {

        if (videogameLinkId !== undefined) {
            const { data, error } = await supabase.from('videogamesLinks').select('*').eq('id', videogameLinkId);
            if (error) {
                console.error("Error fetching video game links", error);
            } else {
                if (data && data.length > 0) {
                    const { nintendoLink, playstationLink, xboxLink } = data[0];
                    setPurchaseLinks({
                        nintendoLink,
                        playstationLink,
                        xboxLink,
                        videogameLinkId
                    });
                    addVideoGamesLinks(data);
                }
            }
        }
    };

    return (
        <Box display="flex" justifyContent="center" alignItems="center">
            <Typography variant="body2">
                Buy Here
            </Typography>
            <Box ml={2} display="flex" alignItems="center">
                {purchaseLinks.xboxLink &&
                    <IconButton href={purchaseLinks.xboxLink} target="_blank">
                        <FontAwesomeIcon icon={faXbox} />
                    </IconButton>
                }
                {purchaseLinks.playstationLink &&
                    <IconButton href={purchaseLinks.playstationLink} target="_blank">
                        <FontAwesomeIcon icon={faPlaystation} />
                    </IconButton>
                }
                {purchaseLinks.nintendoLink &&
                    <IconButton href={purchaseLinks.nintendoLink} target="_blank">
                        <img src={nintendoLogo} alt="Nintendo Logo" style={{ width: '24px', height: '24px' }} />
                    </IconButton>
                }
            </Box>
        </Box>
    );
};
