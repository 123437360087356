import { createRoot } from "react-dom/client";
import { createTheme, CssBaseline, ThemeProvider } from "@mui/material";
import { ErrorBoundary } from "react-error-boundary";
import { RouterProvider } from "react-router-dom";
import { routes } from "./routes.tsx";
import { StrictMode } from "react";
import ErrorFallback from "./components/ErrorBoundary/ErrorFallback";

const rootElement = document.getElementById("root");
if (!rootElement) {
  throw new Error("Root element not found");
}
createRoot(rootElement).render(
  <StrictMode>
    <ThemeProvider theme={createTheme()}>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <CssBaseline />
        <RouterProvider router={routes} />
      </ErrorBoundary>
    </ThemeProvider>
  </StrictMode>
);
