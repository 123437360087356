import { createBrowserRouter, RouteObject } from "react-router-dom";
import { ComingSoon, HowToPage, MainPage, NewsPage } from "./pages";
import { VideoGamesPage } from "./pages/VideoGamesPage";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "./components/ErrorBoundary/ErrorFallback";
import App from "./App";

const routeElements: RouteObject[] = [
  {
    path: "/",

    element: (
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <App />
      </ErrorBoundary>
    ),
    children: [
      {
        path: "/",
        element: (
          <ErrorBoundary FallbackComponent={ErrorFallback}>
            <MainPage />
          </ErrorBoundary>
        ),
      },
      {
        path: "/home",
        element: (
          <ErrorBoundary FallbackComponent={ErrorFallback}>
            <MainPage />
          </ErrorBoundary>
        ),
      },
      {
        path: "/games",
        element: (
          <ErrorBoundary FallbackComponent={ErrorFallback}>
            <VideoGamesPage />
          </ErrorBoundary>
        ),
      },
      {
        path: "/movies",
        element: (
          <ErrorBoundary FallbackComponent={ErrorFallback}>
            <ComingSoon />
          </ErrorBoundary>
        ),
      },
      {
        path: "/howto",
        element: (
          <ErrorBoundary FallbackComponent={ErrorFallback}>
            <HowToPage />
          </ErrorBoundary>
        ),
      },

      {
        path: "/news",
        element: (
          <ErrorBoundary FallbackComponent={ErrorFallback}>
            <NewsPage />
          </ErrorBoundary>
        ),
      },
    ],
  },
];

export const routes = createBrowserRouter(routeElements);
